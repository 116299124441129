.mainContainer {
  margin-top: 5rem 0;
  width: 100%;
  justify-content: center !important;
  align-items: center;
  overflow: hidden !important;
}

/* Footer */
.footer {
  margin: 1rem auto;
  padding: 0rem 5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: #fff;
  font-size: small;

  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}
.tcc p{
  text-align: center;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: smaller;
  padding: 1rem;
}
.footer p {

  color: #fff;
}
.footerHighlight img:hover {
  cursor: pointer;
}
.footerHighlight img {
  float: left;
  width: 80%;
}

.copyright {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: small;
}
/* Social */
.socials {
  padding: 1rem 5rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
  color: #fff;
}
.socials span {
  font-size: 1rem;
}
.socials a:hover {
 font-weight: bold;
}
/* Swing Frame */
@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

/* Form Submitted */
.submittedForm {
  text-align: center;
  margin-top: 2rem;
  color: var(--starwhite);
}

@media (max-width: 1120px) {
}
@media (max-width: 1120px) {
  .slider1 img {
    padding: 0rem;
    width: 100%;
  }
  .slider1 p {
    padding: 0rem;
  }
  .slider2 {
    padding: 2rem 0rem;
  }
  .slider2 img {
    width: 100%;
  }
  .slider2 p {
    padding: 0rem;
  }
}

@media (max-width: 768px) {
  /* Slider 1 */
  .slider1 img {
    width: 100%;
    padding: 2rem 0rem;
  }

  /* Slider2 */

  .slider2 p {
    text-align: left;
    padding: 0.5rem;
    color: var(--starblack);
  }

  .slider2 img {
    width: 100%;
  }

  /* Slider3 */
  .slider3 h1 {
    padding-top: 5rem;
  }

  /* Slider4 */
  .slider4 {
    padding: 2rem 1rem;
  }

  /* Slider6 */
  .slider6 h1 {
    padding-top: 1.5rem;
  }
  .slider6 {
    padding: 0.5rem !important;
  }

  /* Common Containers */
  .container {
    display: block;
    padding: 0rem 2rem;
  }
  .container7 {
    padding: 2rem 2rem;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .container8 {
    padding: 2rem 1rem;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .container7 h3 {
    text-align: center;
  }
  .container8 h3 {
    text-align: center;
  }

  /* Products Card */
  .productsCard {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .productCard h1 {
    margin-top: -2rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }

  /* Signup Newsletter */
  .signUp {
    padding: 1rem;
  }
  .signUpSubcontainer {
    width: 90%;
  }
  .signUpSubcontainer8 {
    width: 90%;
  }
  .footerHighlight img {
    float: initial;
    width: 50%;
  }
  .socials {
    padding: 1rem;
  }
}
