.mainContainer {
  margin: 0;
  padding: 0;
  display: flex;
  width: 100%;
}
.left {
  background-color: var(--divGrey);
  margin: 0rem 1rem;
  width: 20%;
  padding: 1rem;
}
.right {
  margin: 1rem 0rem;
  width: 80%;
  padding: 1rem;
}
.right p {
  margin: 1rem;
  font-size: small;
}
.right li {
  margin: 1rem;
  font-size: small;
}
.menu li {
  padding: 0.5rem;
  list-style: none;
  margin-bottom: 0.5rem;
  line-height: 1.5rem;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.menu li:hover {
  background-color: var(--menuHoverDark);
  color: #fff;
}

@media (max-width: 768px) {
  .mainContainer {
    display: flex;
    flex-direction: column;
  }
  .left {
    width: 100%;
    padding: 1rem;
  }
  .right {
    width: 100%;
    padding: 1rem;
  }
}

/* Sidebar styling */
.sidebar {
  width: 250px;
  height: 100vh;
  background-color: #2c3e50;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

/* Default style for menu items */
.sidebar .menu-item {
  padding: 15px;
  color: #ecf0f1;
  font-size: 18px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  cursor: pointer;
}

/* Active menu item (first item by default) */
.sidebar .menu-item:first-child {
  background-color: #34495e;
  color: #ffffff;
}

/* Hover effect for menu items */
.sidebar .menu-item:hover {
  background-color: #1abc9c;
  color: #ffffff;
}

/* Optional: Style for active state to remain while hovering */
.sidebar .menu-item.active {
  background-color: #34495e;
  color: #ffffff;
}
