:root {
  --background: #ffffff;
  --foreground: #606062;
  --primaryColor: #2d5089;
  --secondaryColor: #50b6cf;
  --buttonHover: #4b4b4d;
  --divGrey: #e6e7e8;
  --menuBackground: #e6e7e8;
  --menuHover: #c2eaf2;
  --menuHoverDark: #163139;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #606062;
  font-size: small;
  line-height: 1.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

p {
  color: #606062;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}
