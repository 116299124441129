/* Main container */
.container {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  display: flex;
  width: 65%;
  gap: 1rem;
  background-color: var(--divGrey);
  justify-content: center;
  align-items: center;
  text-align: center;
}

.container img {
  object-fit: contain;
  width: 35% !important;
  position: relative !important;
  height: unset !important;
  object-fit: cover;
}

.imageFlag {
  width: 20%;
}
.subContainer {
  display: flex;
  flex-direction: row;
  padding-right: 1rem !important;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
@media (max-width: 1296px) {
  .container {
    width: 95%;
  }
  .container img {
    width: 55% !important;
  }
  .sidebar {
    width: 20%;
    padding: 10px;
  }
  .mainContent {
    width: 60%;
  }
}
