.main {
  margin: 0rem;
  display: none;
}

.themesContainer {
  margin: 5rem;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
  gap: 20px;
}

.productCard {
  background-color: #ffffff;
  border-radius: 8px;
  font-size: small;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.productCard:hover {
  cursor: pointer;
  border: solid 0.1rem var(--secondaryColor);
}

.productCard img {
  width: 100%;
  height: auto;
}

.productCard h5 {
  padding: 0.5rem;
  margin: 0;
  color: var(--primaryColor);
  font-size: 18px;
  text-align: center;
  line-height: 1.5rem;
}
.productCard h5:hover {
  color: var(--starmaroon);
}
.productCard p {
  line-height: 1.5rem;
  padding: 0rem 1.5rem;
  text-align: justify;
  font-size: medium;
  color: var(--starblack);
}
.productInfo {
  margin-top: 12.5rem;
  text-align: center;
  color: var(--greyCardTop);
  line-height: 0.025rem;
}

.productCard button {
  margin: 1rem;
  float: right;
  display: block; /* Ensure button takes full width */
  background-color: var(--secondaryColor);
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* Add margin for spacing */
}

.productCard button:hover {
  background-color: var(--buttonHover);
}

.productCard a {
  text-decoration: none;
}
.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  object-fit: cover;
}

@media (max-width: 1120px) {
  .themesContainer {
    margin: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));
    gap: 10px;
    padding: 0.2rem;
  }

  .productInfo {
    font-size: medium;
    padding: 0.5rem;
    text-wrap: wrap;
    line-height: 1.5rem;
  }
}

@media (max-width: 700px) {
  .themesContainer {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    gap: 10px;
    padding: 0.2rem;
  }

  .productInfo {
    margin-top: 12rem;
    line-height: 1.5rem;
    text-wrap: wrap;
    text-align: center;
  }
  .productCard {
    margin: 0.5rem;
    padding: 0.5rem 0rem;
  }
  .productCard h5 {
    padding: 6px;
  }
  .productCard p {
    font-size: small;
  }
  .favorite {
    margin: 0.5rem 15.5rem;
    padding: 0rem 1rem;
  }
  .addcart {
    margin-top: -1rem;
    display: block;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .addcartSection {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
