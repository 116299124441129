/* Main container */
.container {
  display: flex;
  width: 100%;
}

/* Sidebar (left and right) */
.sidebar {
  width: 10%;
  padding: 10px;
}

/* Main content area */
.mainContent {
  width: 80%;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.mainContent h1 {
  font-weight:900;
  color: var(--primaryColor);
}
.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  object-fit: cover;
}
@media (max-width: 1296px) {
  .sidebar {
    width: 20%;
    padding: 10px;
  }
  .mainContent {
    width: 60%;
  }
}
