.mainContainer {
  margin: 2rem;
}
/* Slider2 Containers */
.slider2 {
  background-color: var(--starwhite);
  padding: 0rem 5rem;
}
.slider2 h1 {
  font-weight: bold;
  color: var(--primaryColor);
  margin-top: 1rem;
}
.slider2 p {
  text-align: left;
  padding: 0 2rem;
}
.slider2 li {
  text-align: left;
  padding: 0 3rem;
}
.slider2 img {
  padding: 2rem;
  width: 60%;
}

/* Common Containers */
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}
.productCard {
  text-align: center !important;
}
.productCard img {
  width: 100%;
}

.productCard button {
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--menuHoverDark);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.productCard button:hover {
  background-color: var(--secondaryColor);
}

.productCard span{
  font-size: 4.5rem;
 font-family:serif
}

@media (max-width: 1120px) {
  .productCard h1 {
    margin-top: 1rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }
  /* Slider 2 */
  .slider2 {
    padding: 0.5rem 0.5rem;
  }
  .slider2 p {
    width: 100%;
    padding: 0rem;
  }
  .slider2 img {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .mainContainer {
    margin: 0rem;
  }
  /* Slider 2 */
  .slider2 {
    margin-top: 3rem;
    padding: 2rem 1rem;
  }
  .slider2 p {
    width: 100%;
    padding: 0rem;
  }
  .slider2 h1 {
    margin: 1rem;
  }
  .slider2 li {
    margin: 0.5rem;
  }
  .productCard img {
    width: 100%;
  }
  .vision {
    margin-top: 3rem;
  }
  /* Common Containers */
  .container {
    display: block;
    padding: 0rem 2rem;
  }
  /* ProductCard*/
  .productsCard {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .productCard h1 {
    margin-top: -2rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }
}
