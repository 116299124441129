/* Signup Newsletter Form */
.mainContainer {
  margin: 2rem;
}
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
}
.productCard {
  padding: 1rem;
  text-align: center !important;
}

.productCard p {
  color: #fff;
}
.signUp {
  margin: 2rem 0rem;
  background-image: url("signup.jpg");
  width: 100%;
  color: #fff;
}
.signUpSubcontainer {
  margin-top: 1rem;
  padding: 2rem;
  width: 90%;
  display: inline-flex;
  gap: 2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: relative;
}
.signUp h3 {
  margin: 0 auto;
  text-align: center;
  color: var(--starmaroonhighlight);
}
.signUp input {
  margin: 0 auto;
  border: none;
  border-bottom: 2px solid white;
  background: none;
  color: #fff !important;
  padding: 0.8rem;
  font-size: small;
  outline: none;
  width: 100%;
  transition: all 0.3s ease;
  text-align: center;
}
.signUp :focus {
  border-bottom: 2px solid #fff;
  box-shadow: var(--secondaryColor);
  border-radius: 1rem;
  background-color: var(--menuHover);
  color: var(--starwhite);
  transform: scale(1.025);
}
.signUp button {
  display: block;
  background-color: var(--primaryColor);
  color: #fff;
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
  background-color: var(--primaryColor);
}
.signUp button:hover {
  color: #fff;
  background-color: var(--secondaryColor);
}

@media (max-width: 700px) {
  .mainContainer {
    margin: 0rem;
  }
  .container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
}
