.mainContainer {
  margin: 0rem;
  width: 100%;
  justify-content: center !important;
  align-items: center;
  overflow: hidden !important;
  position: relative;
}
/* Green Backround */
.slider1 {
  padding: 0rem;
  background-color: var(--starbackgroundgreen);
}

.slider1 img {
  padding: 0;
  width: 100%;
}

.notFound {
  margin-top: -10rem;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  position: relative;
  background-color: var(--divGrey);
  padding: 1rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  color: #fff;
  z-index: 10;
  background-color: rgba(54, 133, 236, 0.5);
  border-radius: 1rem;
  cursor: pointer;
  bottom: 20px;
}
.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  object-fit: cover;
}

@media (max-width: 1120px) {
}

@media (max-width: 700px) {
  .slider1 button {
    padding: 1rem;
    margin: -2rem 0.2rem !important;
    width: 40%;
  }
  .slider2 {
    padding: 0rem;
  }
  .slider2 button {
    padding: 0.5rem;
    margin: -2rem 0.2rem !important;
    width: 25%;
  }
}
