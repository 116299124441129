.mainContainer {
  margin: 0.25rem;
}
/* Slider2 Containers */

/* Common Containers */
.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}
.productCard {
  text-align: center !important;
}
.productCard h1 {
  margin: 1rem;
}
.productCard img {
  width: 100%;
}

.productCard button {
  margin: 1rem;
  display: block; /* Ensure button takes full width */
  background-color: var(--menuHoverDark);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.productCard button:hover {
  background-color: var(--secondaryColor);
}

.productCard span {
  font-size: 4.5rem;
  font-family: serif;
}
.table {
  margin: 1rem 0.5rem;
  padding: 2rem;
  width: 100%;
  text-align: left;
}
.table tr {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 5rem !important;
}
.table tr:hover {
  background-color:var( --menuHover);
  cursor: pointer;
}
.tdimage {
  width: 20%;
}
.tdtext {
  padding: 0.75rem;
  width: 80%;
}
.table img {
  width: 100%;
}
.table p {
  width: 100%;
}

@media (max-width: 1120px) {
  .productCard h1 {
    margin-top: 1rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }
  /* Slider 2 */
  .slider2 {
    padding: 0.5rem 0.5rem;
  }
  .slider2 p {
    width: 100%;
    padding: 0rem;
  }
  .slider2 img {
    width: 100%;
  }
}
@media (max-width: 700px) {
  .mainContainer {
    margin: 2rem;
  }
 
  .productCard img {
    width: 100%;
  }
  .vision {
    margin-top: 3rem;
  }
  /* Common Containers */
  .container {
    display: block;
    padding: 1rem;
  }
  /* ProductCard*/
  .productsCard {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }

  .productCard h1 {
    margin-top: -2rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }
  .table {
    margin: 0.5rem;
    padding: 0.5rem;
    width: 100%;
  }
}
