/* Signup Newsletter Form */
.mainContainer {
  margin: 3rem;
  padding: 2rem;
}
.container {
  
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100, 1fr));
}
.productCard {
  padding: 1rem 10rem;
  text-align: center !important;
}

.productCard p {
  color: #fff;
  font-size: large;
}
.signUp {
  margin: 2rem 0rem;
  background-image: url("mission.jpg");
  width: 100%;
  color: #fff;
}

.signUp h2 {
  margin: 0 auto;
  text-align: center;
  color: var(--starmaroonhighlight);
}

@media (max-width: 700px) {
  .mainContainer {
    margin: 0rem;
  }
  .container {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .productCard {
    padding: 1rem;
  }
  .productCard p {
    font-size: medium;
  }
}
