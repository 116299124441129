/* NavBar.css */

.navbar {
  display: flex;
  margin: 0 auto;
  position: -webkit-sticky !important;
  position: sticky !important;
  justify-content: center;
  align-items: center;
  background-color: var(--divGrey);
  top: -5px;
  z-index: 2;
  width: 100% !important;
  height: 100px !important;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
  display: none;
  color: var(--primaryColor);
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}
.logoDisplayMinimized {
  display: none;
}

.hiddenTitle {
  width: 100% !important;
  font-size: 1.5rem;
  text-align: center !important;
}

.hiddenTitle h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.navTitle {
  margin: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.navTitle img {
  width: 35% !important;
}
.nav-links {
  margin-left: 2rem;
  font-size: small;
  text-align: left;
  list-style: none;
  display: flex;
  white-space: nowrap;
  background-color: var(--divGrey) !important;
}

.nav-links li {
  padding-left: 1rem;
  position: relative;
  list-style: none;
  line-height: 2rem;
  color: var(--primaryColor);
}

.sub-links {
  width: max-content;

  position: absolute;
  top: 100%;
  left: 0;
  display: none;
  background-color: var(--divGrey) !important;
  z-index: 1;
  padding: 0;
}

.sub-links li {
  width: 88%;
  color: var(--primaryColor) !important;
  border-bottom: 0.045rem solid #fff;
}
.sub-links a {
  padding: 0.5rem;
  color: var(--primaryColor) !important;
}
.sub-links a:hover {
  color: var(--staryellow);
}
.sub-links li:hover {
  padding: 0%;
  width: 100%;
}

.nav-links li:hover .sub-links {
  display: block;
}

.nonlinks {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar input {
  margin-left: 0;
  width: 75%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
}
.navbar input:focus {
  background-color: var(--secondaryColor);
}
.navbar p {
  padding: 0rem 0.5rem;
  color: #fff;
  cursor: pointer;
}

.TitleLink {
  display: flex;
  margin-top: 0.2rem;
}

.marqueeWrapper {
  width: 100%;
}

@media screen and (max-width: 1296px) {
  .navbar {
    height: 150px;
  }
  .navTitle {
    width: 20%;
    margin: 0 1rem;
  }

  .nav-title h5 {
    text-wrap: balance;
    line-height: 0.75rem !important;
  }
  .navTitle img {
    width: 60% !important;
  }
  .nav-links {
    width: 85% !important;
  }
  .nav-links li {
    text-wrap: balance;
    margin-right: 1rem;
    padding-left: 0.5rem;
    text-align: center;
    line-height: 1rem !important;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    height: 100px;
  }
  .menu-icon {
    margin-top: -1rem !important;
    display: block;
    padding-right: 1rem;
  }
  .logoDisplayMinimized {
    color: var(--starblack);
    justify-content: center;
    align-items: center;
    text-wrap: nowrap;
    display: flex !important;
    flex-direction: row !important;
  }
  .logoDisplayMinimized img {
    width: 100%;
    padding-left: 0.5rem;
  }

  .titleHidden {
    display: none;
  }
  .navTitle {
    display: none;
  }
  .nav-links {
    display: none !important;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    display: none;
    background-color: var(--divGrey);
  }
  .nav-links li {
    margin: 0;
    padding: 0.5rem;
    line-height: 1rem;
    border-bottom: 0.02rem solid #fff;
    text-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  .nav-linksmobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 90px !important;
    display: none;
    background-color: var(--divGrey);
  }
  .nav-linksmobile li {
    margin: 0;
    padding: 0.5rem;
    line-height: 1rem;
    border-bottom: 0.02rem solid #fff;
    text-wrap: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .nav-linksmobile a {
    color: var(--starwhite);
  }

  .sub-links {
    width: max-content;
    position: absolute;
    left: 0rem;
    top: 40px;
    z-index: 1;
  }

  .nav-links.active {
    display: flex;
  }
  .sub-links li {
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
  }
  .sub-links li:hover {
    padding: 0.5rem;
  }
  .socialLinks {
    margin-top: -10rem;
    width: 70%;
    float: right !important;
    margin-right: 10px;
  }
  .hiddenTitle {
    display: none;
  }
}
