.mainContainer {
  margin-top: 1rem;
  display: flex;
  height: 40px;
  color: var(--primaryColor);
  float: right;
  gap: 2em;
  font-size: small;
  cursor: pointer;
  padding-right: 2em;
  margin-bottom: 1rem;
}

.mainContainer p {
  color: var(--primaryColor);
}

.mainContainer p:hover {
  font-weight: bold;
  color: var(--secondaryColor);
}
