.mainContainer {
  margin: 0;
  width: 100%;
  justify-content: center !important;
  align-items: center;
  overflow: hidden !important;
}

/* Slider8 */
.slider8 {
  margin: 0 auto;
  background-color: var(--primaryColor);
  justify-content: center;
  align-items: center;
  text-align: center;
}
.slider8 h3 {
  font-size: 2rem;
  text-align: center;
  color: #ffffff;
}

.slider8 h4 {
  color: #ffffff;
  margin: 0.5rem;
}
.slider8 li {
  margin-top: -0.5rem;
  justify-content: center !important;
  align-items: center !important;
  padding-left: 5rem;
  color: #fff;
  text-align: left;
  line-height: 2.5rem;
  list-style: none;
  font-size: small;
}
.slider8 a {
  color: #fff;
  font-size: small;
  text-decoration: none;
}
.slider8 a:hover {
  color: var(--starwhite) !important;
  font-weight: bold;
}
.slider8 button {
  margin: 1rem;
  display: block;
  background-color: var(--starblack);
  color: #fff;
  padding: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto !important;
}
.image {
  object-fit: contain;
  width: 60% !important;
  position: relative !important;
  height: unset !important;
  object-fit: cover;
}
.imagePlaceholder {
  background-color: #fff;
  border-radius: 50%;
  width: 60%;
  height: 60%;
}

/* Common Containers */
.container {
  margin: 0 auto !important;
  padding: 0rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}

.container8 {
  margin: 0 auto !important;
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
}

/* Product Card */
.productCard {
  width: 100%;
  text-align: center;
  font-size: small;
}

/* Footer */
.footer {
  margin: 2rem auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: small;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

/* Social */
.socials {
  padding: 1rem 5rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  cursor: pointer;
}
.socials span {
  font-size: 1rem;
}
.socials span:hover {
  width: 15%;
  transform: scale(1.1);
}
.contacts h3{
  margin: 0.5rem;
}
.contacts p{
  color: #fff;
  line-height: 1rem;
}

/* Swing Frame */
@keyframes swing {
  0% {
    transform: rotate(10deg);
  }
  100% {
    transform: rotate(-10deg);
  }
}

@media (max-width: 1120px) {
}
@media (max-width: 1120px) {
}

@media (max-width: 768px) {
  .container8 {
    padding: 2rem 1rem;
    text-align: center;
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .container7 h3 {
    text-align: center;
  }
  .container8 h3 {
    text-align: center;
  }

  /* Products Card */
  .productsCard {
    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  }
  .productCard h1 {
    margin-top: -2rem;
    padding-left: 0rem;
    font-size: 2rem;
    text-align: center;
  }

  .footerHighlight img {
    float: initial;
    width: 50%;
  }
  .socials {
    padding: 1rem;
  }
}
