/* Slider.css */
.maincontainer {
  width: 100%;
  position: relative;
}

.container {
  margin-top: -10rem;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  position: relative;
  background-color: var(--divGrey);
  padding: 1rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  color: #fff;
  z-index: 10;
  background-color: rgba(54, 133, 236, 0.5);
  border-radius: 1rem;
  cursor: pointer;
  bottom: 20px;
}
.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slide {
  position: absolute;
  display: flex;
  opacity: 0;
  transition: opacity 1s ease-in-out !important;
}

.slide.active {
  position: relative;
  opacity: 1;
}

img {
  width: 100%;
  height: auto;
}

.indicator-bar {
  position: absolute;
  bottom: 20px;
  right: 5%;
  transform: translateX(-50%);
  display: flex;
}

.indicator {
  width: 20px;
  height: 20px;
  background-color: #f0e3e3;
  border-radius: 50%;
  margin: 10px 5px;
  cursor: pointer;
  z-index: 2;
}

.indicator.active {
  background-color: var(--primaryColor);
}
.image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  object-fit: cover;
}
@media (max-width: 1296px) {
  .container {
    margin-top: -5rem;
    font-size: small;
    width: 80%;
  }
}
